<template>
    <v-dialog v-model="visible" persistent max-width="450px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5">Exportar XML</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text
                style="height: 180px; overflow-y: auto;"
            >
                <v-form ref="form" class="mt-4">
                    <v-row>
                        <v-col>
                            <PeriodoFilter
                                v-model="periodo"
                                default="Mês passado"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="E-mail"
                                :value="email"
                                :rules="[rules.email]"
                                outlined
                                dense
                                hide-details
                                disabled
                                filled
                            />
                            <span class="caption">
                                O e-mail pode ser alterado em Notas Fiscais, no menu mais opções em
                                Configurações no campo E-Mail da Contabilidade
                            </span>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
                <v-btn color="primary" @click="send" :loading="loading">
                    <v-icon>mdi-send</v-icon>&nbsp;Enviar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import PeriodoFilter from '@/components/PeriodoFilter';
import { mapState } from 'vuex';

export default {
    name: 'ExportarXml',

    components: {
        PeriodoFilter,
    },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        loading: false,
        form: {
            email: '',
        },
        periodo: {},
        rules: {
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'E-mail inválido'
            },
        },
    }),

    computed: {
        ...mapState([
            'configuracoes',
        ]),

        email() {
            return this.configuracoes?.fiscal?.email_contabilidade || '';
        },
    },

    methods: {
        send() {
            // if (!this.$refs.form.validate()) {
            //     this.notify('Preencha todos os campos', 'warning');
            //     return;
            // }

            this.loading = true;
            this.$http.post('fiscal/exportar-xml', this.periodo).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }

                this.notify('Relatório será enviado por e-mail em instantes');
                this.$emit('close');
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0;
}
</style>
