<template>
    <BaseContainer>
        <span slot="principal">
            <v-main
                v-if="isModuloFiscalActive"
                class="pr-2 pl-2"
                style="background-color: #e0e0e0;"
            >
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title>
                        Notas Fiscais
                    </v-toolbar-title>
                    <v-spacer/>
                    <PeriodoFilter
                        v-model="periodo"
                        @update="consultar"
                    />
                    <v-spacer/>

                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item @click="openConfig">
                                <v-list-item-title>
                                    <v-icon class="mr-2">mdi-cog</v-icon>
                                    <span>Configurações</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="openExportXml">
                                <v-list-item-title>
                                    <v-icon class="mr-2">mdi-email</v-icon>
                                    <span>Exportar XML</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="exportPdf">
                                <v-list-item-title>
                                    <v-icon class="mr-2">mdi-file-pdf-box</v-icon>
                                    <span>Exportar PDF</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="openNotaEntrada">
                                <v-list-item-title>
                                    <v-icon class="mr-2">mdi-import</v-icon>
                                    <span>Nota de Entrada</span>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            @click:row="$emit('clickRow', $event)"
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 220"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :options.sync="options"
                        >
                            <template v-slot:item.total="{ item }">
                                <span style="color: green;">R${{ formatPrice(item.pedido.total) }}</span>
                            </template>
                            <template v-slot:item.created_at="{ item }">
                                <span>{{ formatDate(item.created_at) }}</span>
                            </template>
                            <template v-slot:item.danfe="{ item }">
                                <v-btn @click="openLink(item.danfe)" color="primary" icon>
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                                <v-btn @click="printDanfe(item.danfe)" color="primary" icon>
                                    <v-icon>mdi-printer</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:item.xml="{ item }">
                                <v-btn @click="openLink(item.xml)" color="primary">
                                    <span>XML</span>
                                </v-btn>
                            </template>
                            <template v-slot:item.ambiente="{ item }">
                                <span>{{ item.ambiente == 1 ? 'Produção' : 'Homologação' }}</span>
                            </template>
                        </v-data-table>
                        <Paginate
                            v-model="pagination"
                            @input="consultar"
                        />
                    </v-card-text>
                </v-card>
            </v-main>

            <SetupNotas v-else />

            <Configuracoes
                v-if="isModuloFiscalActive"
                :visible="visible"
                @onSave="onSave"
                @close="visible = false"
            />
            <ExportarXml
                v-if="isModuloFiscalActive"
                :visible="visible2"
                @close="visible2 = false"
            />
            <NotaEntrada
                v-if="isModuloFiscalActive"
                :visible="visible3"
                @close="visible3 = false"
            />
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import Paginate from '@/components/Paginate';
import PeriodoFilter from '@/components/PeriodoFilter';
import Configuracoes from './Configuracoes';
import SetupNotas from './SetupNotas';
import ExportarXml from './ExportarXml';
import NotaEntrada from './NotaEntrada';
import { mapState } from 'vuex';
import { printDanfe, openDanfe } from '@/utils/utils';

export default {
    name: 'NotasFiscais',

    components: {
        BaseContainer,
        Configuracoes,
        SetupNotas,
        Paginate,
        PeriodoFilter,
        ExportarXml,
        NotaEntrada,
    },

    data: () => ({
        visible: false,
        visible2: false,
        visible3: false,
        loading: false,
        dados: [],
        headers: [
            { text: 'NFE', value: 'nfe' },
            { text: 'Série', value: 'serie' },
            { text: 'Pedido', value: 'pedido_id' },
            { text: 'Total', value: 'total' },
            { text: 'Status', value: 'status' },
            { text: 'Motivo', value: 'motivo' },
            { text: 'Emissão', value: 'created_at' },
            { text: 'Danfe', value: 'danfe' },
            { text: 'Xml', value: 'xml' },
            { text: 'Ambiente', value: 'ambiente' },
        ],
        periodo: {},
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 0,
        },
        options: {},
    }),

    mounted() {
        this.periodo = {
            start: this.moment().startOf('month').toDate(),
            end: this.moment().endOf('month').toDate(),
        };
    },

    watch: {
        options: {
            handler() {
                this.consultar();
            },
            deep: true,
        },
    },

    computed: {
        ...mapState([
            'configuracoes',
        ]),

        isModuloFiscalActive() {
            return !!this.configuracoes.fiscal;
        },
    },

    methods: {
        async consultar() {
            this.loading = true;
            this.dados = [];

            const sortBy = this.options.sortBy.length ? `&sortField=${this.options.sortBy[0]}` : '';
            const sortOrder = this.options.sortDesc.length ? `&sortOrder=${this.options.sortDesc[0] ? 1 : -1}` : '';

            const start = this.moment(this.periodo.start).format('YYYY-MM-DD HH:mm');
            const end = this.moment(this.periodo.end).format('YYYY-MM-DD HH:mm');

            const filter = `&start=${start}&end=${end}`;
            const params = `page=${this.pagination.current_page}${filter}${sortBy}${sortOrder}`;

            await this.$http.get(`notas?${params}`).then(resp => {
                this.pagination = resp.data.meta;
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
                this.loading = false;
            });
        },

        openConfig() {
            this.visible = true;
        },

        openExportXml() {
            this.visible2 = true;
        },

        openNotaEntrada() {
            this.visible3 = true;
        },

        onSave() {
            this.visible = false;
        },

        openLink(url) {
            openDanfe(url);
        },

        printDanfe(url) {
            printDanfe(url);
        },

        getParams() {
            const params = new URLSearchParams();

            const start = this.moment(this.periodo.start).format('YYYY-MM-DD HH:mm');
            const end = this.moment(this.periodo.end).format('YYYY-MM-DD HH:mm');

            params.append('report', '\\App\\Reports\\NotasReport');
            params.append('start', start);
            params.append('end', end);
            params.append('page', this.pagination.current_page);

            if (this.options.sortBy.length) {
                params.append('sortField', this.options.sortBy[0]);
            }
            if (this.options.sortDesc.length) {
                params.append('sortOrder', this.options.sortDesc[0] ? 1 : -1);
            }

            return params.toString();
        },

        async exportPdf() {
            this.$root.$emit('loading', true);
            try {
                const params = this.getParams();
                const resp = await this.$gerarPdf(params);
                if (resp?.type === 'warning') {
                    this.notify(resp.msg, 'warning');
                }
            } finally {
                this.$root.$emit('loading', false);
            }
        },
    },
}
</script>
